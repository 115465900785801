import PropTypes from 'prop-types'
import { Field, Form, Formik, getIn } from 'formik'
import React from 'react'
import { Redirect, matchPath } from 'react-router-dom'
import isEqual from 'react-fast-compare'

import { login } from '../validate/login'
import AgentSelector from './AgentSelector'
import TextInput from './common/forms/inputs/Text'
import Loader from './common/Loader'
import Footer from './Footer'
import { Button } from './ui/Button'
import { logEvent } from './../utils'


class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: null,
      secure_match: null,
      insecure_match: null,
      show_password: false,
      autofilled: false,
      redirect: false
    }
    this.form = false
    this.oauthcode = null
    this.oauthstate = null
    this.username = null
    this.isEnter = this.isEnter.bind(this)
    this.ssoButtonTracking = this.ssoButtonTracking.bind(this)
    let params
    if (this.props.location) {
      let { search } = this.props.location
      if (search && search.slice(0, 1) === '?') {
        search = search.substring(1)
      }
      params = new URLSearchParams(search)
      this.oauthcode = params.get('code')
      this.oauthstate = params.get('state')
    }
    if (process && process.env && process.env.REACT_APP_ENV === 'production') {
      this.microsoft_client_id = '3fc8400a-df82-40c6-8637-4220e4a83754'
      this.microsoft_redirect_uri = 'https://manage.propdata.net'
      this.google_client_id = '138932331119-isf81kj22bbe22pt5kgqe686vpjhlnd7.apps.googleusercontent.com'
      this.google_redirect_uri = 'https://manage.propdata.net'
    } else if (process && process.env && process.env.REACT_APP_ENV === 'staging') {
      this.microsoft_client_id = '9f77edcb-3ab4-4f02-9355-dc431360a2d3'
      this.microsoft_redirect_uri = 'https://staging.manage.propdata.net'
      this.google_client_id = '354992808711-0q65k2b5302eshe17o6a4cfouhhsr4s1.apps.googleusercontent.com'
      this.google_redirect_uri = 'https://staging.manage.propdata.net'
    } else { // Dev
      this.microsoft_client_id = '5c294276-ea59-4a45-ab69-dc1c8d3e20b9'
      this.microsoft_redirect_uri = 'http://localhost:3000'
      this.google_client_id = '9625771130-9nph9qhmrhsqm6fhrh2q3mn793edlids.apps.googleusercontent.com'
      this.google_redirect_uri = 'http://127.0.0.1:3000'
    }
  }

  componentDidMount() {
    const { actions } = this.props
    if (this.props.ui.msg) {
      this.setState({ status: this.props.ui.msg })
    }
    if (getIn(this.props.location, 'state.from')) {
      this.props.actions.registerRedirect(getIn(this.props.location, 'state.from')) // Redirects user back to where they were
    }
    if (this.oauthcode && this.oauthstate && this.oauthstate.includes('lightstone')) {
      const provider_parts = this.oauthstate.split('/')
      const storedtoken = localStorage.getItem('token')
      const agent = localStorage.getItem('agent')
      if (agent && storedtoken && ![ null, undefined, '', 'null', 'undefined', false ].includes(storedtoken)) {
        actions.registerRedirect(`/secure/${provider_parts[1]}/integrations?state=${this.oauthstate}&code=${this.oauthcode}`)
        this.setState({ redirect: true })
      }
    } else {
      setTimeout(() => {
        this.checkOAuth()
      }, 500)
    }
    window.addEventListener('keydown', this.isEnter)
    if (this.username) { this.username.focus() }
  }

  componentDidUpdate() {
    const { actions, user, ui } = this.props
    const secure_match = matchPath(getIn(ui.redirect, [ 'pathname' ], ui.redirect), { path: '/secure/:site(\\d+)', exact: false, strict: false })
    const insecure_match = matchPath(getIn(ui.redirect, [ 'pathname' ], ui.redirect), { path: '/', exact: false, strict: false })
    if (
      (secure_match && !isEqual(secure_match, this.state.secure_match))
      || (insecure_match && !isEqual(insecure_match, this.state.insecure_match))
    ) {
      this.setState({ secure_match, insecure_match }, () => {
        if (user.auth && user.agent && !this.state.redirect) {
          if (ui.redirect && ui.redirect?.pathname !== '/login' && this.state.secure_match) {
            actions.registerRedirect(ui.redirect) // Redirects user back to where they were
          } else {
            actions.registerRedirect({ pathname: `/secure/${user.agent.site.id}/branches`, search: '?limit=20&active__in=1&order_by=name' }) // Redirects user back to where they were
          }
          this.setState({ redirect: true })
        }
      })
    } else if (user.auth && user.agent && !this.state.redirect) {
      if (ui.redirect && ui.redirect?.pathname !== '/login' && this.state.secure_match) {
        actions.registerRedirect(ui.redirect) // Redirects user back to where they were
      } else {
        actions.registerRedirect({ pathname: `/secure/${user.agent.site.id}/branches`, search: '?limit=20&active__in=1&order_by=name' }) // Redirects user back to where they were
      }
      this.setState({ redirect: true })
    } else if (user.auth && !user.agent && ui.redirect && !this.state.redirect) {
      this.setState({ redirect: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.isEnter)
  }

  checkOAuth() {
    if (this.form && this.oauthcode) {
      this.form.submitForm()
    }
  }

  doLogin = (values, actions) => {
    this.oauthcode = false
    new Promise((resolve, reject) => {
      this.props.actions.doLogin({ values, resolve, reject })
    }).catch(e => {
      actions.setSubmitting(false)
      if (e.message) { // JS error
        this.setState({
          status: {
            type: 'error',
            msg: e.message
          }
        })
        actions.setStatus({
          type: 'error',
          msg: e.message
        })
      } else if (e.raw) { // Services error
        this.setState({
          status: {
            type: 'error',
            msg: e.raw.detail
          }
        })
        actions.setStatus({
          type: 'error',
          msg: e.raw.detail
        })
      } else if (e.error) { // Services error
        this.setState({
          status: {
            type: 'error',
            msg: e.error
          }
        })
        actions.setStatus({
          type: 'error',
          msg: e.error
        })
      }
    })
  }

  isEnter(e) {
    if (e.keyCode === 13 && this.form) {
      this.form.submitForm()
    }
  }

  ssoButtonTracking(client_name) {
    logEvent('SSO_LOGIN_INITIATED', {
      sso_client_name: client_name,
      sso_client_id: client_name === 'Microsoft' ? this.microsoft_client_id : this.google_client_id
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.props.ui.redirect} />
    }
    const { user, ui } = this.props
    let loading = false
    if (user.auth && !user.agent) {
      return (<AgentSelector user={this.props.user} actions={this.props.actions} ui={this.props.ui} />)
    } else if (user.auth && user.agent) { // We have a user and a site, just waiting
      loading = true
    }
    if (ui.isLoading) { loading = true }
    if (this.oauthcode) { loading = true }
    return (
      <div className="wrapper login">
        <div className="content">
          <div className="content-wrapper">
            {loading &&
              <Loader noblock={true} />
            }
            <div className="login-box">
              <div className="pd-logo"></div>
              <h3>Sign in to start your session</h3>

              <Formik
                initialValues={{
                  username: '',
                  password: '',
                  code: this.oauthcode,
                  state: this.oauthstate
                }}
                validationSchema={login}
                onSubmit={this.doLogin}
              >
                {({ isSubmitting, ...formik }) => {
                  this.form = formik
                  return (
                    <Form>
                      <div className="login-box-body">
                        <Field
                          type="email"
                          name="username"
                          forwardRef={el => (this.username = el)}
                          placeholder="eg. joe@myproperties.com"
                          autoComplete="username"
                          label="Email"
                          component={TextInput}
                          id="username"
                        />
                        <Field
                          type={this.state.show_password ? 'text' : 'password'}
                          name="password"
                          resetPassword
                          autoComplete="current-password"
                          placeholder="Password"
                          label="Password"
                          suffix={(
                            <Button
                              icon="#icon24-EyeOpen"
                              type="button"
                              onContextMenu={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                e.cancelBubble = true
                                e.returnValue = false
                                return false
                              }}
                              onTouchStart={e => {
                                e.preventDefault()
                                this.setState({ show_password: true })
                              }}
                              onMouseDown={e => {
                                e.preventDefault()
                                this.setState({ show_password: true })
                              }}
                              onMouseLeave={e => {
                                e.preventDefault()
                                this.setState({ show_password: false })
                              }}
                              onTouchEnd={e => {
                                e.preventDefault()
                                this.setState({ show_password: false })
                              }}
                              onMouseUp={e => {
                                e.preventDefault()
                                this.setState({ show_password: false })
                              }}
                              className="input-group-addon btn btn-icon-24 btn-none"
                            />
                          )}
                          component={TextInput}
                          id="password"
                        />

                        <div className="login-box-buttons">
                          <Button
                            type="button"
                            onClick={e => {
                              e.preventDefault()
                              setTimeout(() => {
                                formik.submitForm()
                              }, 250)
                            }}
                            className="btn btn-primary btn-icon-right btn-icon-16"
                            icon={'#icon16-ArrowRight'}
                            disabled={isSubmitting}
                          >
                            Sign in
                          </Button>
                        </div>
                        {this.state.status ? (
                          <div className={this.state.status ? `${this.state.status.type} message` : 'message'}>
                            <div className="msg warn">{this.state.status && this.state.status.msg}</div>
                          </div>
                        ) : null}
                      </div>
                    </Form>
                  )
                }}
              </Formik>

              {/* {process && process.env && process.env.REACT_APP_ENV !== 'production' && */}
              <div className="social-logins login-box-body">
                <span>Or login with</span>
                <div>
                  <a className="google-login" onClick={() => this.ssoButtonTracking('Google')} href={`https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&state=google&redirect_uri=${this.google_redirect_uri}&client_id=${this.google_client_id}`}>
                    <svg viewBox="0 0 32 32"><use href="/images/social.svg#social-Google"/></svg>Google
                  </a>
                  <a className="microsoft-login" onClick={() => this.ssoButtonTracking('Microsoft')} href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.microsoft_client_id}&response_type=code&redirect_uri=${this.microsoft_redirect_uri}&response_mode=query&scope=openid+profile+email&state=microsoft`}>
                    <svg viewBox="0 0 32 32"><use href="/images/social.svg#social-Microsoft"/></svg>Microsoft
                  </a>
                </div>
              </div>
              {/* } */}

            </div>
            <div className="login-box-footer">
              <a href="http://support.propdata.net/" target="_blank" rel="noopener noreferrer">Need Help? Click here for Support</a>
              <Footer />
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  ui: PropTypes.object
}

export default Login

/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import PropTypes from 'prop-types'
import { Formik, Field, getIn } from 'formik'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { CONFIG, MINUSER, MODELROUTE, CACHEDMODELID, CURRENCY } from '../../selectors'
import CustomForm from '../common/forms/CustomForm'
import TextInput from '../common/forms/inputs/Text'
import { Button } from '../ui/Button'
import { sortBy, updateSearchParms, valueFormat, useCustomCompareMemo } from '../../utils'
import QueryBuilder from '../common/QueryBuilder'
import ModelActions from '../common/ModelActions'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import ReportEditSidebar from '../../containers/ReportEditSidebar'
import WideSidebar from '../ui/sidebar/WideSidebar'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'
import Tab from '../common/tabs/Tab'
import { toggleWideSidebar, fetchOne, exportData } from '../../actions'
import Loader from '../common/Loader'


const fetchReport = ({ actions, id, modelname }) => {
  new Promise((resolve, reject) => actions.fetchOne('reports', id, resolve, reject)).then(r => {
    const report = r.reports[id]
    actions.setReport(report)
    new Promise((resolve, reject) => actions.fetchOne('templates', r.reports[id].template, resolve, reject)).then(t => {
      const template = t.templates[r.reports[id].template]
      actions.setTemplate(template)
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        const params = {
          params: report.report_filters,
          modelname,
          args: {
            action: 'report',
            template: template.name
          },
          label: report.name,
          noalert: true,
          callback: actions.setData,
          resolve,
          reject
        }
        // console.log(params, report, template)
        return actions.exportData(params)
      })
    })
  })
}

const ReportTable = props => {
  const { match, config, reports_config, location, user, actions, sidebar, currency } = props
  const qs = new QueryBuilder(location.search)
  const [ report, setReport ] = useState()
  const [ categories, setCategories ] = useState([])
  const [ category, setCategory ] = useState(match.params.tab)
  const [ search, setSearch ] = useState(qs.getAllArgs(false))
  const [ template, setTemplate ] = useState()
  const [ editTemplate, setEditTemplate ] = useState(qs.getAllArgs(false))
  const [ data, setData ] = useState()
  useEffect(() => {
    setData(null)
    fetchReport({
      actions: {
        fetchOne: actions.fetchOne,
        exportData: actions.exportData,
        setReport,
        setTemplate,
        setData
      },
      id: match.params.id,
      modelname: config.modelname
    })
  }, [ useCustomCompareMemo(report) ])

  useEffect(() => {
    if (editTemplate?.id) {
      actions.toggleWideSidebar('show-report-edit')
    }
  }, [ useCustomCompareMemo(editTemplate) ])

  if (!report || !template) { return null }
  return (
    <>
      <Formik
        initialValues={{
          ...search
        }}
        enableReinitialize={true}
        onSubmit={(values, form) => {
          updateSearchParms('term', values.term)
          form.setTouched({})
          form.setSubmitting(false)
        }}
      >{formik => (
          <div className="content">
            <div className="viewhead details">
              <div className="action-bar">
                <ModelActions
                  touched={formik.touched}
                  errors={formik.errors}
                  handleSubmit={e => {
                    e.preventDefault()
                  }}
                  actions={{
                    toggleSidebar: () => {
                      actions.toggleWideSidebar('show-report-edit')
                    }
                  }}
                  isSubmitting={formik.isSubmitting}
                  form={formik}
                  extras={{
                    addreport: {
                      label: 'Add Report',
                      menu: null,
                      redirect: null,
                      icon: '#icon16-Plus',
                      action: 'toggleSidebar',
                      className: 'btn-round btn-red'
                    }
                  }}
                  modelname={config.modelname}
                  statusmsg={formik.status ? formik.status.msg : false}
                />
              </div>
            </div>
            <div className='view container-fluid'>
              <div className='viewcontent'>
                <div>
                  <Card
                    header={<h3>{report.name}</h3>}
                    background
                    body={
                      <>
                        <div className="report-table-criteria">
                          {valueFormat('criteria', report, { fields: template.fields })}
                          <div className="report-table-criteria-buttons">
                            <Button id="edit_report" className="btn btn-primary" onClick={() => (editTemplate?.id === report.id ? setEditTemplate(null) : setEditTemplate(report))} title="Edit Report" type="button">Edit Report</Button>
                          </div>
                        </div>
                      </>
                    }
                  />
                  <Card
                    header={null}
                    background
                    body={
                      <div className="report-table-wrapper">
                        {data?.status !== 200 ? (
                          <Loader inline />
                        ) : null}
                        {data?.status === 200 ? (
                          <SimpleTable
                            config={{
                              ...config,
                              fields: [
                                ...config.fields,
                                ...data.response.tableconfig
                              ]
                            }}
                            summerize_footer={data.response.summerize_footer}
                            action={null}
                            params={null}
                            parser={results => {
                              results.options = data.response.data
                              results.hasMore = false
                              return results
                            }}
                            currency={currency}
                            data={data.response.data}
                            header={data.response.tableconfig}
                            user={user}
                          />
                        ) : null}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <WideSidebar sidebar={'show-report-edit'}>
        <ReportEditSidebar
          modelname={config.servicename}
          templates={[ template ]}
          report={editTemplate}
          sidebar={'show-report-edit'}
          setEditTemplate={setEditTemplate}
          setReport={setReport}
          match={match}
        />
      </WideSidebar>
    </>
  )
}

ReportTable.propTypes = {
  location: PropTypes.object,
  config: PropTypes.object,
  reports_config: PropTypes.object,
  user: PropTypes.object,
  routeConfig: PropTypes.object,
  match: PropTypes.object,
  currency: PropTypes.string,
  sidebar: PropTypes.string,
  actions: PropTypes.object
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  const { model, id } = ownProps.match.params
  const user = MINUSER(state)
  const config = CONFIG(state, model)
  const reports_config = CONFIG(state, 'reports')
  const routeConfig = MODELROUTE(state, model)
  const report = CACHEDMODELID(state, model, id)
  const currency = CURRENCY(state)

  return {
    user,
    config,
    report,
    reports_config,
    routeConfig,
    currency
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    exportData,
    fetchOne,
    toggleWideSidebar
  }, dispatch)
})

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(ReportTable))


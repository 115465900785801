/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import { Formik, getIn } from 'formik'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import config from '../../config/deal-financial-structure.json'
import { SETTINGS } from '../../selectors'
import { Button } from '../ui/Button'
import { hasPermission } from '../../utils'
import CustomForm from './forms/CustomForm'
import FieldGroup from './forms/FieldGroup'
import Card from './Card'


const Financialstructure = props => {
  const { model, actions, user } = props

  const [ canEdit, setCanEdit ] = useState(hasPermission([ 'deals_update' ], user.permissions))
  const isSeller = hasPermission([ 'deals_update_own' ], user.permissions) && (props.form.values.selling_agents.includes(user.agent.id) || props.form.values.agent === user.agent.id)
  const [ isSuper, setIsSuper ] = useState(hasPermission([ 'deals_update_super' ], user.permissions))
  const [ isDealFinal, setIsDealFinal ] = useState([ 'Final', 'Closed', 'Cancelled', 'Deleted' ].includes(props.form.values.status))

  const abortController = useRef(new AbortController())
  const [ mounted, setMounted ] = useState(true)
  const [ structure, setStructure ] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [ groups, setGroups ] = useState(Object.keys(config.config.fieldgroups))

  const loadStructure = useCallback(() => {
    new Promise((resolve, reject) => actions.fetchDealFinancialStructure(
      {
        id: model.id,
        signal: abortController.current.signal
      },
      resolve,
      reject
    )).then(r => {
      if (mounted) {
        const total_property_price = [
          'deposit_amount',
          'bond_amount',
          'proceeds_from_sale',
          'cash_amount'
        ].reduce((total, nextVal) => (total + parseFloat(getIn(r, nextVal, 0.0) || 0.0)), 0.0)
        setStructure({
          ...r,
          total_property_price,
          agent: model.agent,
          selling_agents: model.selling_agents,
          status: model.status
        })
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
    })
  }, [ model.id ])

  const updateStructure = values => {
    new Promise((resolve, reject) => actions.updateDealFinancialStructure(
      {
        ...values,
        id: model.id,
        signal: abortController.current.signal
      },
      resolve,
      reject
    )).then(r => {
      if (mounted) {
        const total_property_price = [
          'deposit_amount',
          'bond_amount',
          'proceeds_from_sale',
          'cash_amount'
        ].reduce((total, nextVal) => (total + parseFloat(getIn(r, nextVal, 0.0) || 0.0)), 0.0)
        setStructure({
          ...r,
          total_property_price,
          agent: model.agent,
          selling_agents: model.selling_agents,
          status: model.status
        })
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
    })
  }

  useEffect(() => {
    loadStructure()
    return () => {
      abortController.current.abort()
      setMounted(false)
    }
  }, [])

  return (
    <Formik
      initialValues={structure}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={updateStructure}
    >{formik => (
        <CustomForm
          id="content"
          component="div"
          onChange={(changes, fmk, form) => {
            if (Object.keys(formik.touched).length > 0) { // Only fire when touched
              const total_property_price = [
                'deposit_amount',
                'bond_amount',
                'proceeds_from_sale',
                'cash_amount'
              ].reduce((total, nextVal) => (total + parseFloat(getIn(form.values, nextVal, 0.0) || 0.0)), 0.0)
              if (total_property_price !== formik.values.total_property_price) {
                formik.setFieldValue('total_property_price', total_property_price, false)
              }
            }
          }}
          render={() => (
            <Card
              background={true}
              collapsable={false}
              header={
                <>
                  <h3>Financial Structure</h3>
                  <div className="details-section-buttons tablemeta">
                    {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                      <div className="commission-save">
                        <Button type="button" onClick={formik.submitForm} className="btn btn-red btn-round">Save Structure</Button>
                      </div>
                    ) : null}
                  </div>
                </>
              }
              bodyclass="no-top-padding"
              body={() => (
                groups.map((group, gidx) => (
                  <FieldGroup
                    card={false}
                    key={`group-${gidx}`}
                    groupname={group}
                    user={user}
                    config={config.config}
                    fields={config.config.fields}
                  />
                ))
              )}
            />
          )}
        />
      )}
    </Formik>
  )
}

Financialstructure.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
  form: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  model: PropTypes.object,
  currency: PropTypes.string
}

const mapStateToProps = state => {
  const settings = SETTINGS(state)
  return ({
    currency: settings.get('default_currency')
  })
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(Financialstructure))

import * as yup from 'yup'
import messages from './messages.json'


export const leads = () => yup.object({
  status: yup.string().oneOf([ 'Active', 'Inactive', 'Non-Compliant', 'No-Consent', null ], messages.invalid).required(messages.required).nullable(),
  contact: yup.number().integer().typeError('Please select a contact').min(1).required(messages.required),
  listing_model: yup.string().when('lead_type', lead_type => {
    if ([ 'Buyer', 'Tenant' ].includes(lead_type)) {
      return yup.string().oneOf([
        'residential',
        'commercial',
        'holiday',
        'project',
        null
      ], messages.invalid).required(messages.required).nullable()
    }
    return yup.string().nullable()
  }),
  lead_type: yup.string().oneOf([
    'Buyer',
    'Tenant',
    'Seller',
    'Landlord',
    'Property Owner',
    'General Enquiry',
    null
  ], 'Please select a lead type').nullable().required(messages.required),
  source: yup.string().oneOf([
    'Ananzi',
    'Bayut',
    'Board',
    'Canvassing',
    'Dubizzle',
    'Email Campaign',
    'Facebook',
    'FindHomes',
    'Flow',
    'Flyer / Pamphlet',
    'Google Display',
    'Google Search',
    'Gumtree',
    'Houza',
    'ImmoAfrica',
    'Instagram',
    'JamesEdition',
    'LinkedIn',
    'ListGlobally',
    'Microsite',
    'Mobi Site',
    'Offr',
    'Pamphlet',
    'Partner',
    'Personal Contact',
    'Phone In',
    'Previous Client',
    'Print Ad',
    'Private Property',
    'Promotion',
    'Propertiez.co.za',
    'Property 24',
    'Property Central',
    'Property Finder',
    'Property House',
    'Property Portal',
    'Radio Ad',
    'Referral',
    'Showhouse',
    'Social Media',
    'Spouse / Partner',
    'TV Ad',
    'Valuation',
    'Walk-in',
    'Website',
    'Other'
  ], messages.invalid).required(messages.required),
  agent: yup.number().integer().positive().nullable(),
  team: yup.number().integer().positive().nullable(),
  branch: yup.number().integer().positive().nullable(),
  source_ref: yup.string().max(100).nullable(),
  source_description: yup.string().nullable().required(messages.required),
  residential: yup.string().when('listing_model', {
    is: 'residential',
    then: yup.string().typeError('Please select a listing').required(messages.required)
  }),
  commercial: yup.string().when('listing_model', {
    is: 'commercial',
    then: yup.string().typeError('Please select a listing').required(messages.required)
  }),
  holiday: yup.string().when('listing_model', {
    is: 'holiday',
    then: yup.string().typeError('Please select a listing').required(messages.required)
  }),
  project: yup.string().when('listing_model', {
    is: 'project',
    then: yup.string().typeError('Please select a listing').required(messages.required)
  }),
  message: yup.string().required(),
  profile: yup.number().integer().positive().nullable()
})

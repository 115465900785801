/* eslint-disable no-console */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import isEqual from 'react-fast-compare'

import { hasPermission, isInViewport } from '../../../utils'
import { Button } from '../../ui/Button'
import { Scrollbar } from '../../ui/Scrollbars'
import Tab from './Tab'


const HorizontalTabs = props => {
  const [ scroll, setScroll ] = useState(false)
  const [ inView, setInView ] = useState([])
  const [ outView, setOutView ] = useState([])
  const [ scrollLeft, setScrollLeft ] = useState(0)
  const el = useRef()
  const scroller = useRef()

  const isActive = (e, tab_type) => {
    if (tab_type) {
      return props.match && getIn(props.match, 'params.tab', '').indexOf(e) !== -1 && getIn(props.match, 'params.profiletype', '').indexOf(tab_type) !== -1
    }
    return props.match && getIn(props.match, 'params.tab', '').indexOf(e) !== -1
  }

  const scrollLeftStart = () => {
    if (!el.current) { return }
    const inner = el.current.querySelectorAll('.horizontal-tabs-content')[0]
    const elements = Array.prototype.slice.call(inner.children)
    if (outView.length) {
      const firstInView = Math.min(...inView)
      const prevOutView = Math.max(0, firstInView - 1)
      if (firstInView > prevOutView) {
        const el1 = elements[prevOutView]
        const parent = el1.offsetParent.getBoundingClientRect()
        let newScrollTo = el1.getBoundingClientRect().left - 66
        if (parent.left) {
          newScrollTo = 0 - parent.left - el1.getBoundingClientRect().left + 66
        }
        if (prevOutView === 0) { newScrollTo -= 9999 }
        setScrollLeft(newScrollTo)
      }
    }
  }


  const scrollRightStart = () => {
    if (!el.current) { return }
    const inner = el.current.querySelectorAll('.horizontal-tabs-content')[0]
    const elements = Array.prototype.slice.call(inner.children)
    if (outView.length) {
      const lastInView = Math.max(...inView)
      const nextOutView = Math.min(Math.max(...outView), lastInView + 1)
      if (lastInView < nextOutView) {
        const el1 = elements[lastInView]
        const el2 = elements[nextOutView]
        let newScrollTo = el2.getBoundingClientRect().left - el1.getBoundingClientRect().left
        if (nextOutView === Math.max(...outView)) {
          newScrollTo += 20
        }
        setScrollLeft(newScrollTo)
      }
    }
  }

  const handleUpdate = values => {
    const { scrollWidth, clientWidth, scrollLeft: newScrollLeft } = values
    if (clientWidth < scrollWidth && !scroll) {
      setScroll(true)
    } else if (clientWidth >= scrollWidth && scroll) {
      setScroll(false)
    }
    if (el.current && scroll) {
      const inner = el.current.querySelectorAll('.horizontal-tabs-content')[0]
      const elements = Array.prototype.slice.call(inner.children)
      const newInView = []
      const newOutView = []
      elements.map((el1, idx) => {
        const in_view = isInViewport(el1, 20)
        if (!in_view && !newOutView.includes(idx)) {
          newOutView.push(idx)
        } else if (in_view && !newInView.includes(idx)) {
          newInView.push(idx)
        }
      })
      if (!newInView.length && newOutView.length) {
        const first = newOutView.shift()
        newInView.push(first)
      }
      const inViewEqual = isEqual(inView.sort(), newInView.sort())
      const outViewEqual = isEqual(outView.sort(), newOutView.sort())
      if (!inViewEqual || !outViewEqual) {
        setInView(newInView)
        setOutView(newOutView)
      }
    }
    if (scrollLeft !== null) {
      const newLeft = newScrollLeft + scrollLeft
      scroller.current.view.scrollTo({
        top: 0,
        left: newLeft,
        behavior: 'smooth'
      })
      setScrollLeft(null)
    }
  }


  const { config: { view, edit, add }, match, model, defaultTab, user } = props
  const { tab, profiletype, action } = match.params
  let { children } = props
  if (children && !Array.isArray(children)) {
    children = [ children ]
  }
  const inner = []
  let tabs = view ? view.tabs : null
  if (action === 'edit') {
    tabs = edit.tabs
  }
  if (action === 'add') {
    tabs = add.tabs
  }
  if (!tabs) {
    return null
  }
  if (!tab) {
    // Try to load the default tab
    if (tabs[defaultTab]) {
      let mockform = {}
      if (Array.isArray(tabs[defaultTab].permission_key)) {
        tabs[defaultTab].permission_key.forEach(k => {
          mockform[k] = model[k]
        })
      } else if (tabs[defaultTab].permission_key) {
        mockform[tabs[defaultTab].permission_key] = model[tabs[defaultTab].permission_key]
      } else {
        mockform = null
      }
      if (
        !tabs[defaultTab].permissions ||
        hasPermission(
          tabs[defaultTab].permissions,
          user.permissions,
          mockform,
          user.agent.id,
          tabs[defaultTab].permission_key,
          true
        )
      ) {
        return <Redirect to={`${match.url}/${defaultTab}`} />
      }
    }
    // If the default tab is not accessible due to perms,
    // load the first tab that we do have perms for
    const new_tab = Object.keys(tabs).find(t => {
      let mockform = {}
      if (Array.isArray(tabs[t].permission_key)) {
        tabs[t].permission_key.forEach(k => {
          mockform[k] = model[k]
        })
      } else if (tabs[t].permission_key) {
        mockform[tabs[t].permission_key] = model[tabs[t].permission_key]
      } else {
        mockform = null
      }
      return (!tabs[t].permissions ||
        hasPermission(
          tabs[t].permissions,
          user.permissions,
          mockform,
          user.agent.id,
          tabs[t].permission_key,
          true
        )
      )
    }) // Do no render if no perms exist for tab
    return new_tab ? <Redirect to={`${match.url}/${new_tab}`} /> : null
  }
  Object.keys(tabs).map(t => {
    let mockform = {}
    if (Array.isArray(tabs[t].permission_key)) {
      tabs[t].permission_key.forEach(k => {
        mockform[k] = model[k]
      })
    } else if (tabs[t].permission_key) {
      mockform[tabs[t].permission_key] = model[tabs[t].permission_key]
    } else {
      mockform = null
    }
    if (
      tabs[t].permissions &&
      !hasPermission(
        tabs[t].permissions,
        user.permissions,
        mockform,
        user.agent.id,
        tabs[t].permission_key,
        true
      )
    ) {
      return null
    } // Do no render if no perms exist for tab
    if (tabs[t].addons && props.addons && !props.addons.some(a => tabs[t].addons.includes(a))) {
      return null
    }
    const thisTab = children.find(e => {
      if (!e) { return false }
      return e.props.tab === t
    })
    let label = ''

    if (thisTab) { label = thisTab.props.label }
    // eslint-disable-next-line max-len
    if (view && view.types && Object.keys(view.types).includes(t)) { // For profile types etc. Buyer profile
      view.types[t].map(type => {
        const thisType = children.find(e => e.props.profiletype === type)
        if (thisType) { label = thisType.props.label }
        inner.push(
          <Button component={NavLink} id={`tab-${type}`} className="btn btn-none horizontal-tab" to={`/secure/${match.params.model}${match.params.log ? `/${match.params.log}` : ''}${match.params.id ? `/${match.params.id}` : ''}/${t}/${type}`} isActive={() => isActive(t, type)} activeClassName="active" key={`${t}-${type}-tab`}>
            {label}
          </Button>
        )
        return type
      })
      return null
    }
    inner.push(
      <Button component={NavLink} id={`tab-${t}`} className="btn btn-none horizontal-tab" to={`/secure/${match.params.model}${match.params.log ? `/${match.params.log}` : ''}${match.params.id ? `/${match.params.id}` : ''}${action ? `/${action}` : ''}/${t}`} isActive={() => isActive(t)} activeClassName="active" key={`${t}-tab`}>
        {label}
      </Button>
    )
    return t
  }).filter(t => t)
  let outer = children.filter(e => e && e.props.tab === tab).map(e => {
    if (e.type === Tab) {
      return (
        <Tab
          {...e.props}
          key={`tab-${tab}`}
          active
        >
          {e.props.children}
        </Tab>
      )
    }
    return e
  })
  if (view && view.types && view.types[tab] && view.types[tab].includes(profiletype)) {
    outer = children.filter(e => e.props.profiletype === profiletype).map(e => {
      if (e.type === Tab) {
        return (
          <Tab
            {...e.props}
            key={`tab-${profiletype}-${tab}`}
            active
          >
            {e.props.children}
          </Tab>
        )
      }
      return e
    })
  }

  return (
    <div ref={el} className={`${props.className ? props.className : ''} horizontal-tabs-container${scroll ? ' has-scroll' : ''}`}>
      <div className={`${props.className ? props.className : ''} horizontal-tabs-wrapper${scroll ? ' has-scroll' : ''}`}>
        <nav className={`horizontal-tabs-nav${scroll ? ' has-scroll' : ''}`}>
          {scroll &&
            <div className="for-scroll scroll-left">
              <Button type="button" icon="#icon24-ChevronLeft" className="btn btn-icon-24 btn-white btn-icon-only" onMouseDown={scrollLeftStart} onTouchStart={scrollLeftStart} />
            </div>
          }
          <Scrollbar
            innerRef={scroller}
            autoHeight
            onUpdate={values => handleUpdate(values) }
          >
            <div className="horizontal-tabs-content">
              {inner}
            </div>
          </Scrollbar>
          {scroll &&
            <div className="for-scroll scroll-right">
              <Button type="button" icon="#icon24-ChevronRight" className="btn btn-icon-24 btn-white btn-icon-only" onMouseDown={scrollRightStart} onTouchStart={scrollRightStart} />
            </div>
          }
        </nav>
      </div>
      {outer}
    </div>
  )
}

HorizontalTabs.propTypes = {
  addons: PropTypes.array,
  defaultTab: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  user: PropTypes.object
}

export default HorizontalTabs

/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import PropTypes from 'prop-types'
import { Formik, Field, getIn } from 'formik'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { CONFIG, MINUSER, MODELROUTE, SIDEBAR } from '../../selectors'
import CustomForm from '../common/forms/CustomForm'
import TextInput from '../common/forms/inputs/Text'
import { Button } from '../ui/Button'
import { sortBy, updateSearchParms, buildOptionLabel, overwriteMerge, uniqueArray, useCustomCompareMemo } from '../../utils'
import QueryBuilder from '../common/QueryBuilder'
import ModelActions from '../common/ModelActions'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import ReportEditSidebar from '../../containers/ReportEditSidebar'
import WideSidebar from '../ui/sidebar/WideSidebar'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'
import Tab from '../common/tabs/Tab'
import { toggleWideSidebar, fetchMany } from '../../actions'


const mergeOptions = (newResults, props) => {
  const results = newResults.map(o => ({
    value: getIn(o, 'meta.category.id'),
    label: getIn(o, 'meta.category.name')
  }))
  if (props.labelgrouper) {
    const all_options = results.map(group => {
      group.options = newResults.filter(g => getIn(g, 'meta.category.id') === group.value).map(g => buildOptionLabel(props, g))
      return group
    })
    return all_options
  }
  return []
}

const fetchReports = ({ actions, modelname, params }) => {
  new Promise((resolve, reject) => {
    const values = {
      modelname: 'templates',
      params: {
        model__in: [ modelname ],
        template_type: 'report',
        meta_fields: [ 'category', 'reports' ],
        get_all: 1,
        ...params
      }
    }
    return actions.fetchMany({ values, resolve, reject })
  }).then(r => {
    console.log(r)
    const reports = { none: [] }
    r.options.map(o => {
      let cat = getIn(o, 'meta.category')
      if (!cat) {
        cat = { slug: 'none' }
      }
      if (!getIn(reports, cat.slug)) {
        reports[cat.slug] = []
      }
      reports[cat.slug].push(o)

      return cat
    })

    actions.setTemplates(r.options)
    actions.setSearch(params)
    actions.setReports(reports)
  })
}


const fetchCategories = ({ actions, modelname, params }) => {
  new Promise((resolve, reject) => {
    const values = {
      modelname: 'templatecategories',
      params: {
        website_templates__template__model: modelname,
        website_templates__template__template_type: 'report',
        get_all: 1,
        ...params
      }
    }
    return actions.fetchMany({ values, resolve, reject })
  }).then(r => {
    actions.setCategories([ {
      slug: 'all',
      name: 'All'
    }, ...sortBy(r.options, 'slug') ])
  })
}

const ReportList = props => {
  const { match, config, reports_config, location, user, actions, sidebar } = props
  const qs = new QueryBuilder(location.search)
  const [ reports, setReports ] = useState({})
  const [ categories, setCategories ] = useState([])
  const [ category, setCategory ] = useState(match.params.tab)
  const [ search, setSearch ] = useState(qs.getAllArgs(false))
  const [ templates, setTemplates ] = useState(qs.getAllArgs(false))
  const [ editTemplate, setEditTemplate ] = useState(qs.getAllArgs(false))
  useEffect(() => {
    fetchCategories({
      actions: {
        fetchMany: actions.fetchMany,
        setCategories,
        setReports,
        setSearch
      },
      modelname: config.servicename
    })
  }, [])
  useEffect(() => {
    const uqs = new QueryBuilder(location.search)
    fetchReports({
      actions: {
        fetchMany: actions.fetchMany,
        setCategories,
        setReports,
        setSearch,
        setTemplates
      },
      modelname: config.servicename,
      params: uqs.getAllArgs(false)
    })
  }, [ category, config.servicename, location.search ])


  useEffect(() => {
    if (editTemplate?.id) {
      actions.toggleWideSidebar('show-report-edit')
    }
  }, [ useCustomCompareMemo(editTemplate) ])

  if (!categories.length) {
    return null
  }
  return (
    <>
      <Formik
        initialValues={{
          ...search
        }}
        enableReinitialize={true}
        onSubmit={(values, form) => {
          updateSearchParms('term', values.term)
          form.setTouched({})
          form.setSubmitting(false)
        }}
      >{formik => (
          <div className="content">
            <div className="viewhead details">
              <div className="action-bar">
                <ModelActions
                  touched={formik.touched}
                  errors={formik.errors}
                  handleSubmit={e => {
                    e.preventDefault()
                  }}
                  actions={{
                    toggleSidebar: () => {
                      actions.toggleWideSidebar('show-report-edit')
                    }
                  }}
                  isSubmitting={formik.isSubmitting}
                  form={formik}
                  extras={{
                    addreport: {
                      label: 'Add Report',
                      menu: null,
                      redirect: null,
                      icon: '#icon16-Plus',
                      action: 'toggleSidebar',
                      className: 'btn-round btn-red'
                    }
                  }}
                  modelname={config.modelname}
                  statusmsg={formik.status ? formik.status.msg : false}
                />
              </div>
            </div>
            <div className='view container-fluid'>
              <div className='viewcontent'>
                <div>
                  <Card
                    header={null}
                    background
                    body={
                      <>
                        <div className="templates-search">
                          <CustomForm
                            component="div"
                            render={el => (
                              <div className="search-fields">
                                <div className="field col-lg-4">
                                  <Field
                                    id="report-search"
                                    name="term"
                                    placeholder="Keyword Search"
                                    component={TextInput}
                                    className="term"
                                    bounce={true}
                                    show_search
                                    form_el={el}
                                    suffix={<Button icon="#icon24-Search" type="button" disabled={formik.isSubmitting} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                                  />
                                </div>
                                {categories.map(c => (
                                  <Button
                                    key={`btn-${c.slug}`}
                                    type="button"
                                    onClick={e => {
                                      if (c.slug === 'all') {
                                        updateSearchParms('category__slug', null)
                                      } else {
                                        updateSearchParms('category__slug', c.slug)
                                      }
                                    }}
                                    className={classNames(
                                      'btn', {
                                        'btn-primary': (search.category__slug === c.slug || (!search.category__slug && c.slug === 'all')),
                                        'btn-subtle': search.category__slug !== c.slug && (!search.category__slug && c.slug !== 'all')

                                      })}
                                  >{c.name}</Button>
                                ))}
                                {search.term &&
                              <div className="reset-group">
                                <strong>This list is filtered</strong>
                                <Button
                                  id="keyword-search-btn"
                                  tabIndex="-1"
                                  type="button"
                                  icon="#icon16-Refresh"
                                  onClick={() => {
                                    formik.resetForm({ values: {} })
                                    formik.submitForm()
                                  }}
                                  disabled={formik.isSubmitting}
                                  className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                                >
                                  Reset
                                </Button>
                              </div>
                                }
                              </div>
                            )}
                          />
                        </div>
                      </>
                    }
                  />
                  {
                    categories.map(cat => {
                      if (cat.slug === 'all') { return null }
                      if (search.category__slug && search.category__slug !== cat.slug) {
                        return null
                      }
                      const cat_templates = getIn(reports, cat.slug, [])
                      const fields = uniqueArray(cat_templates.map(t => t.fields).reduce((prevList, nextList) => prevList.concat(nextList), []).filter(t => t?.name), 'name')
                      const cat_reports = cat_templates.filter(t => getIn(t, 'meta.reports')).map(({ meta, ...temp }) => meta.reports?.map(rep => ({
                        ...rep,
                        meta: {
                          ...rep.meta,
                          template: temp
                        }
                      }) || [])).reduce((prevList, nextList) => prevList.concat(nextList), [])
                      if (!cat_reports.length) {
                        return null
                      }
                      console.log(cat_reports)
                      return (
                        <Card
                          key={`cat-${cat.slug}`}
                          header={(
                            <>
                              <h3>{cat.name}</h3>
                            </>
                          )}
                          background
                          body={
                            <div className="reportsbody">
                              <SimpleTable
                                user={user}
                                config={reports_config}
                                header={[
                                  {
                                    label: 'Name',
                                    name: 'name',
                                    orderable: false
                                  },
                                  {
                                    label: 'Template',
                                    name: 'meta.template.display_name',
                                    orderable: false,
                                    orderby: 'template__name'
                                  },
                                  {
                                    label: 'Criteria',
                                    name: 'report_filters',
                                    multi: false,
                                    input: 'FieldArray',
                                    fields,
                                    format: 'criteria',
                                    orderable: false
                                  },
                                  {
                                    label: 'Created',
                                    name: 'created',
                                    format: 'datetime',
                                    orderable: false
                                  }
                                ]}
                                data={cat_reports}
                                parser={response => {
                                  const data = {
                                    options: response
                                  }
                                  return data
                                }}
                                rowActions={(row, data) => (
                                  <>
                                    <Button icon="#icon16-EyeOpen" component={NavLink} className="btn btn-icon-16 btn-icon-only btn-none" to={`/secure/:site/${match.params.model}/reports/${data.id}`} title="View Report" type="button" />
                                    <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => (editTemplate?.id === data.id ? setEditTemplate(null) : setEditTemplate(data))} title="Edit Report" type="button" />
                                    {data.status !== 'Inactive' ? (
                                      <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={console.log} title="Archive Report" type="button" />
                                    ) : null}
                                  </>
                                )}
                              />
                            </div>
                          }
                        />
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <WideSidebar sidebar={'show-report-edit'}>
        <ReportEditSidebar
          modelname={config.servicename}
          templates={templates}
          report={editTemplate}
          sidebar={'show-report-edit'}
          setEditTemplate={setEditTemplate}
          // toggleLookup={this.toggleEdit}
          // template={this.state.edit}
          // list={this.state.list}
          match={match}
        />
      </WideSidebar>
    </>
  )
}

ReportList.propTypes = {
  location: PropTypes.object,
  config: PropTypes.object,
  reports_config: PropTypes.object,
  user: PropTypes.object,
  routeConfig: PropTypes.object,
  match: PropTypes.object,
  sidebar: PropTypes.string,
  actions: PropTypes.object
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  const { model } = ownProps.match.params
  const user = MINUSER(state)
  const config = CONFIG(state, model)
  const reports_config = CONFIG(state, 'reports')
  const routeConfig = MODELROUTE(state, model)

  return {
    user,
    config,
    reports_config,
    routeConfig
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    fetchMany,
    toggleWideSidebar
  }, dispatch)
})

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(ReportList))

